<template>
  <div class=" mx-auto">
    <div>
      <Loading :isLoading="this.isLoading" />
      <div>
        <form @submit="registerReferal">
          <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
            <div class="">
              <p class="title md:text-center sm:text-left">
                  Referir persona</p>
            </div>
          </div>
          <br>
          <div class="flex" style="margin-top:0px;">
            <div class="w-full">
              <p class="description md:text-center sm:text-left RobotoLight">
                Para referir un contacto debes enviar un correo electrónico o un SMS llenando los datos que se le solicitan a continuación.
              </p>
            </div>
          </div>
          <br>
          <br>
          <div class="flex justify-center" style="margin-top:0px;">
            <div class="md:w-1/2 w-full">
              <div class="w-full">
                <div class="">
                  <p style="font-family:Roboto;color:#00000;font-size:20px;text-align: left;font-weight:700;">
                    <b>Datos de referencia</b>
                  </p>
                  <div class="form-group" style="text-align: left;">
                      <input type="text" style="height:40px;" class="shadow-md border-2 rounded-lg py-5 w-full px-5" id="fullname" required
                      minlength="3" maxlength="60" v-model="fullname" placeholder="Nombre completo del Referido" >
                  </div>
                  <div class="form-group mt-5" style="text-align: left;">
                      <input type="text" style="height:40px;" class="shadow-md border-2 rounded-lg py-5 w-full px-5" id="email" required
                      minlength="3" maxlength="50" @blur="blurEmail(email)" v-model="email" placeholder="Correo Electrónico">
                  </div>
                  <div class="form-group mt-5" style="text-align: left;">
                      <input type="text" v-maska="'### ###-####'" style="height:40px;" class="shadow-md border-2 rounded-lg py-5 w-full px-5" id="cellphone" required
                      minlength="12" maxlength="12" v-model="cellphone" @keyup="validateNumberCellPhone()" placeholder="Celular">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <div class="md:w-1/2 w-full">
              <div class="w-full">
                <button class="" type="submit" style="width:100%;height:50px;margin-top:10px;" id="button_popups">Enviar</button>
              </div>
            </div>
          </div>
        </form>
        <div style="margin-top:40px;" hidden>
          <div class="flex justify-center">
            <div class="md:w-1/2 w-full">
              <p class="description md:text-center sm:text-left">
                *También puedes enviar tu enlace de referencia a tus contactos:
              </p>
            </div>
          </div>
          <div class="flex justify-center">
            <div class="md:w-1/2 w-full">
              <div class="tooltipCopy">
                <input type="text" class="inputs-form copyTextInput italic" disabled
                  value="http://bit.ly/qwert14"
                  style="height:40px;padding:6px 12px 6px 12px;" id="copyTextInput" />
                <button type="button" v-on:click="copyText()" v-on:mouseout="outFunc()" id="copyTextButton" class="copyTextButton" value="">
                  <span class="tooltiptext" id="myTooltip">Copiar al Portapapeles</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
  </div>
</template>



<script>
import('@/assets/css/stylesheet.css')
import Swal from "sweetalert2";
import Loading from '@/components/Loading/VueLoading.vue'
import MenuLateral from '@/components/Cuenta/MenuLateral.vue'
import { sendReferal, generateReference } from '@/api/references'
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
    data() {
      return {
        fullname:"",
        email:"",
        cellphone:"",
        user_id:"",
        isLoading: false,
        ccapi:process.env.VUE_APP_CCAPI,
        referalCode:""
      }
    },
    name: 'ReferPerson',
    props: {
        msg: String
    },
    components: {
        MenuLateral,
        Loading
    },
    async beforeMount() {
      await this.loadInfo();
    },
    mounted() {
      document
        .getElementById("fullname")
        .addEventListener("keyup", ({detail}) => {
          var regex = /^[a-zA-ZÀ-ÿ\s]+$/;
          if (regex.test(this.fullname) != true) {
            this.fullname = this.fullname.replace(/[^a-zA-ZÀ-ÿ@\s]+/, "");
          }
        });
    },
    methods:{
      Logout(){
        sessionStorage.clear();
        this.$router.push({path:"/"});
        // window.location.href = "/"
      },
      async loadInfo() {
        var auth = getTokenDecoden();
        this.user_id = auth["id"];
        this.referalCode = auth.obj["referal_code"];
      },
      async registerReferal(e) {
        e.preventDefault();
        this.isLoading = true;

        if (!this.validateEmail(this.email)) {
          this.isLoading = false;
          return;
        }

        await sendReferal(
          sessionStorage.getItem("login"),
          this.fullname.toLowerCase(),
          this.referalCode,
          this.email,
          this.removeMask(this.cellphone),
          this.user_id
        )
        .then((response) => {
          window.localStorage.setItem("referenced", this.fullname.toLowerCase());
          // window.location.href = "/cuenta/referidos/referencia-exitosa"
          this.$router.push({path:"/cuenta/referidos/referencia-exitosa"});
          this.isLoading = false;
        })
        .catch((error) => {
          if(error.response.data.response.message == "ya existe una cuenta activa con este correo"){
            Swal.fire({
              title: "Aviso",
              text: "No se puede enviar una invitacion a un correo con cuenta activa",
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            });
          }else{
            Swal.fire({
              title: "Aviso",
              text: "Se presentó un error al enviar invitacion",
              icon: "error",
              confirmButtonColor: '#FEB72B',
            });
          }
          this.isLoading = false;
        });     
      },
      async validateNumberCellPhone(){
        var input = document.getElementById('cellphone');
        input.oninvalid = input.setCustomValidity('');
        if(input.value.length > 0 && input.value.length < 12){
          input.oninvalid = input.setCustomValidity('Debe ingresar los 10 dígitos del número');
        }
      },
      removeMask(phone) {
        if (phone.includes("(")) {
          phone = phone.replace("(", "");
        }
        if (phone.includes(")")) {
          phone = phone.replace(")", "");
        }
        if (phone.includes("-")) {
          phone = phone.replace("-", "");
        }
        if (phone.includes(" ")) {
          phone = phone.replace(" ", "");
        }
        return phone;
      },
      blurEmail(email) {
        console.log("entró");
        this.validateEmail(email)
      },
      validateEmail(email) {
        var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (regex.test(email)) {
          return true;
        } else {
          Swal.fire({
            title: "Aviso",
            text: "Favor de seguir el siguiente formato en el Correo Electrónico: ejemplo@correo.com",
            icon: "info",
            confirmButtonColor: '#FEB72B',
          });
          return false;
        }
      },
      copyText() {
        let copyText = document.getElementById("copyTextInput");
        copyText.contentEditable = true;
        copyText.readOnly = true;
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        let tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copiado: " + copyText.value;
      },
      outFunc() {
        let tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copiar al Portapapeles";
      }
    }
}
</script>


<style>

#button_popups {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #feb72b;
  color: #fff;
  font-weight:bold;
  width: 250px;
  height: 40px;
  padding-top: 0%;
  font-size:16px;
}
.tooltipCopy {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltipCopy .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 0%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipCopy .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltipCopy:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.copyTextInput[type="text"] {
    width: 100%;
    height: 40px;
}

.copyTextButton{ 
  background-image: url("../../../assets/Cuenta/copy_box.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.copyTextButton[type="button"] {
    position: absolute;
    margin-left: -50px;
    height: 40px;
    width: 50px;
    background: #D2D2D2;
    color: white;
    border: 1px solid #810042;
    border-left: 0px;
    -webkit-appearance: none;
}

input[type="text"]:focus-visible,
#input_password:focus-visible {
  border: 2px solid #810042 ;
  outline: none;
}
</style>